.notification {
  display: flex;
  flex-direction: column;
  isolation: isolate;
  position: relative;
  /* width: 34rem; */
  overflow: hidden;
  font-size: 14px;

  background: #29292c;
  border-radius: 1rem;
  /* --gradient: linear-gradient(to bottom, #2eadff, #3d83ff, #7e61ff); */
  --gradient: linear-gradient(to bottom,#562E7E,
  #9c3ef9,
  #7129b9);
  --color: #32a6ff
}

.notification:before {
  position: absolute;
  content: "";
  inset: 0.0625rem;
  border-radius: 0.9375rem;
  background-color: #1A2029;
  background-image: radial-gradient(circle at 100% 0, rgba(158, 156, 255, .4), rgba(0, 0, 0, 0) 8%), radial-gradient(circle at 100% 0, rgba(158, 156, 255, .3), rgba(23, 25, 30, 0) 12%);
  z-index: 2
}

.notification:after {
  position: absolute;
  content: "";
  width: 0.25rem;
  inset: 0.65rem auto 0.65rem 0.5rem;
  border-radius: 0.125rem;
  background: var(--gradient);
  transition: transform 300ms ease;
  z-index: 4;
}

.notification:hover:after {
  transform: translateX(0.15rem)
}

.notititle {
  /* color: var(--color); */
  background-image: linear-gradient(96deg, #dddcff, #c1bfff);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  padding: 0.65rem 0.25rem 0.4rem 1.25rem;
  font-weight: 500;
  font-size: 16px;
  transition: transform 300ms ease;
  z-index: 5;
}

.notification:hover .notititle {
  transform: translateX(0.15rem)
}

.notibody {
  color: #b8b8b8;
  padding: 0 1.25rem;
  transition: transform 300ms ease;
  z-index: 5;
}

.notification:hover .notibody {
  transform: translateX(0.15rem)
}

.notiglow,
.notiborderglow {
  position: absolute;
  width: 20rem;
  height: 20rem;
  transform: translate(-50%, -50%);
  background: radial-gradient(circle closest-side at center, white, transparent);
  opacity: 0;
  transition: opacity 300ms ease;
}

.notiglow {
  z-index: 3;
}

.notiborderglow {
  z-index: 1;
}

.notification:hover .notiglow {
  opacity: 0.1
}

.notification:hover .notiborderglow {
  opacity: 0.1
}