@keyframes glow {
    0% {
      filter: drop-shadow(0 0 0.5rem blue);
    }
    50% {
      filter: drop-shadow(0 0 2rem rgb(116, 1, 79));
    }
    100% {
      filter: drop-shadow(0 0 0.5rem blue);
    }
  }
  
  .glow-effect {
    animation: glow 2s infinite ease-in-out;
  }