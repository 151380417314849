.dashboard-container {
    background-color: #232627;
    color:white;
}
.public-dashboard-container {
    background-color: #232627;
    color:white;
    margin-top:0px;
}
.dashboard-nav, .dashboard-nav2 {
    --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    display:flex;
    justify-content: space-between;
    align-items: center;
    position:fixed;
    width:100%;
    background-color: #232627;
    top:50px;
    z-index: 999;
    height:45px;
}
.dashboard-nav{
    justify-content: flex-start;
    gap: 1.5rem;
}
.dashboard-nav2 {
    top: 90px;
}
.dashboard-page {
    
}
.dashboard-content {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width:100%;
    margin-top:95px;
    overflow:auto;
    height:calc(100vh - 95px);
    padding:0.2rem;
}
.dashboard-left,.dashboard-right {
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width:50%;
    background: #141718;
    margin:0.25rem;
    padding:0.2rem;
    border-radius: 16px;
    height:100%;
    flex-grow: 1;
}
@media (min-width:768px){
    .dashboard-left,.dashboard-right {
        width:50%;
    }
}
.dashboard-background {
    background-color: #232627;
    border-radius:12px;
    border:1px solid #fefefe1a;
}
.bar-chart,.leader-board,.pie-chart {
    margin-bottom:8px;
    min-height:40%;
    overflow:auto;
    width:100%;
    flex-grow: 1;
}

.leader-board h2 {
    
    font-size: 1.125rem/* 18px */;
    line-height: 1.75rem/* 28px */;

}
.leader-board-heading-cont,.leader-board-footer-container {
    position:sticky;
    width:100%;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    background-color: #232627;
    padding: 0.25rem 0.5rem;
    top:0px;
    display:flex;
    justify-content: space-between;
    align-items: center;
}
.leader-board-footer-container {
    bottom:0px;
    width: 100%;
}
.chart-parameters-container,.nav-rewards-container,.sw-subject-container {
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin:0.4rem 0.6rem;
    border: 1px solid #393B3C;
    border-radius:8px;
    background:#232627;
    padding:0.2rem;
}
.leader-board-table {
    margin-top:0.5rem;
}

.nav-rewards-container{
    justify-content: space-between;
    padding:2px;
    font-weight: 500;
    width:50%;
    gap:0.2rem;
}
.sw-analysis-btn {
    text-align: center;
    width:50%;
    margin-left:auto;
    margin-right: auto;
    padding: 0px 8px;
    display: flex;
    justify-content: center;
    height:100%;
    padding:0.3rem;
    background-color: #343839;
}
.chart-parameters {
    display:block;
    appearance:none;
    padding:8px 16px 8px 16px;
    line-height: 1.25;
    outline: none;
    background-color: #343839;
    border-radius:12px;
}
.interval{
    margin:0.15rem;
}
.reward-points-para {
    background-color: #343839;
    padding:4px 8px;
    border-radius: 8px;
    width:50%;
    text-align: center;
}
.pie-chart {
    margin-left: auto;
    margin-right:auto;
}
.pie-chart-center-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.leader-board-list .header, .leader-board-list li {
    display: flex;
    justify-content: space-between;

  }
  
  .leader-board-list .header {
    font-weight: bold;
    background-color: transparent;
  }
  
  .leader-board-list li,.leader-board-footer-container li {
   border-radius:0.5rem;
   padding:0.25rem 0.5rem;
   margin:0.25rem;
   justify-content: space-around;
   border: 0.1rem solid #fefefe1a;
   display: flex;
   align-items: center;
   background-color: #232627;
  }
  
  .leader-board-list li a,.leader-board-footer-container li a{
    text-decoration: none;
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width:35%;
  }


  .pagination {
    display: flex;
    justify-content: center;
  }
  
  .pagination button {
    margin: 0 0.2rem;
    padding: 0.2rem;
  }
  
  .pagination select {
    margin-left: 10px;
    padding: 5px 10px;
  }
  .leader-board-list .header, .leader-board-footer-container .header {
   background-color:  rgba(44, 32, 18, 1);
   border-radius:0.5rem;
   padding:0.25rem 0.5rem;
   margin:0.25rem;
   color:#ffc107;
   justify-content: space-around;
  }

  .avatar {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    margin-right: 8px;
    font-size: 16px;
    font-weight: bold;
    background-color: #343839;
    margin-left:0.5rem;
  }
  .question-subject-progress,.question-subject-progress1 {
    display:flex;
    flex-direction: row;
    gap:0.2rem;
    justify-content: flex-start;
    width:45%;
  }
  
  .question-subject-progress div, .question-subject-progress1 div,.header {
    
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius:0.5rem;
    margin:0.2rem;
    padding:0.1rem 0.2rem;
    
  }
  .question-subject-progress span {
    margin-left:0.2rem;
  }
.current-user-data {
    border-radius: 0.5rem;
    padding: 0.25rem 0.5rem;
    margin: 0.25rem;
    justify-content: space-around;
    border: 0.1rem solid #fefefe1a;
    display: flex;
    align-items: center;
    background-color: #232627;
}
.leader-board-footer-container ul {
    width:100%;
}
.sw-subject-container{
    padding:0.25rem;
    gap:0.5rem;
    margin-bottom: 1rem;
}
.sw-subject-container button {
    margin:0px;
}

.sw-container {
    width:100%;
    overflow: auto;
    height: auto;
    display:flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.active-subject {
    color:#ffc107 ;
    font-weight: 700;
    background-color: rgba(44, 32, 18, 1);
}
.fchart-container {
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    width:100%;
    align-self: flex-start;
    height:95%;
}
.public {
    width:auto;
    height:40px;
    padding:0.3rem;
}

.chart-area {
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height:19rem !important;
    width:100%;
    /* overflow: hidden; */
}

.chart-headings {
    font-size:1.2rem;
    line-height:1.3rem;
    font-weight:600;
    padding:0.5rem 1rem;
    text-align:left;
    width:100%;
    position:sticky;
    top:0.5rem;
}
.active-btn {
    background-color: #fefefe8a;
    color:#343839;
    padding:0.3rem;
    font-weight: 600;
}
.gradient-border {
    border:0px 0px 0px 8px;
    text-align: center;
    border-width: 8px; /* Adjust thickness */
    border-style: solid;
    border-image: linear-gradient(
      to right, 
      transparent, 
      #fefefe, /* Change to desired color */
      #fefefe, /* Central color */
      transparent
    ) 1 stretch;
  }
  