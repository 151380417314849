body {
  background: #ffffff;
  /*transition-property: background-color;
  transition-duration: 200ms; */
  line-height: 1.5;
  background-color: #e7e7e7 !important;
}

/* RegistrationForm.css */
.registration-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(45deg, #6c5ce7, #0984e3);
}

.registration-form {
  background: #fff;
  padding: 20px 40px;
  width: 300px;
  border-radius: 10px;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
}

.registration-form h2 {
  text-align: center;
  margin-bottom: 20px;
}

.registration-form input {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 0px;
}

.registration-form .register-btn {
  width: 100%;
  padding: 10px;
  background: #0984e3;
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  margin-bottom: 10px;
}

.google-btn {
  text-align: center !important;
  justify-content: center !important;
  width: 100%;
}

.registration-form a {
  color: #0984e3;
  text-decoration: none;
}

.registration-form a:hover {
  text-decoration: underline;
}

.sign-in-container {
  max-width: 400px;
  margin: 50px auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.quesiton-answer {
  display: flex;
  overflow: auto;
  position: absolute;
  height: calc(100vh-90px);
  width: 100%;
  background-color: rgba(35, 38, 39, 1);
  z-index: 800;
}

.question-data-wrapper,
.right-section {
  overflow: auto;
  border-radius: 8px;
  padding: 0.4rem;
  margin: 0.5rem;
  background: #141718;
  flex-grow: 1;
}
.right-section {
  margin-left: 5px;
}

.solution-upload-component,
.result-component {
  /* flex: 1; */
  /* overflow-y: auto; */
  margin-bottom: 20px; /* Optional: Adds some space between the components */
  border: 1px solid #fefefe1a;
  border-radius: 8px;
}

/* Ensure .solution-upload-component has a min-height to prevent it from becoming too small */
.solution-upload-component {
  min-height: 300px; /*Adjust based on your needs */
  /* flex-grow: 1; */
}
/* QuestionDataComponent.css */

.question-data-wrapper,
.solution-upload-component {
  overflow: auto;
  background: #141718;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
.question-data-wrapper,
.right-section {
  height: 97%;
  color: white;

  flex: 1;
  /* flex-grow: 1; */
}

.problem-statement {
  display: flex;
  flex-direction: column;
  max-height: 100%;
}
.problem-description-container,
.utilities {
  background: rgba(35, 38, 39, 0.5);
  /* padding: 0.75rem; */
  border-radius: 8px;
  margin-bottom: 12px;
  width: 100%;
}

.data-table-container {
  margin-bottom: 20px;
}

.utilities {
  min-height: 40vh;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

button {
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 5px;
  /*transition: background-color 0.3s ease; */
}

.excel-download {
  background-color: #e2f1f8;
  color: #007bff;
}

.show-hint {
  background-color: #fffbea;
  color: #ffc107;
}

button:focus {
  outline: none;
  /*box-shadow: 0 0 0 3px rgba(21, 156, 228, 0.4); */
}

button:active {
  transform: scale(0.98);
}

table {
  border-radius: 8px;
  border-collapse: collapse;
  margin: 12px;
}
.table-container {
  max-height: calc(100% - 20px);
  overflow-x: scroll;
  /* width:100%; */
}

table,
th,
td {
  border: 1px solid rgba(254, 254, 254, 0.1);
}

th,
td {
  text-align: left;
  padding: 8px;
  color: #fefefe;
}

th,
thead {
  background-color: #232627;
}

tr:nth-child(even) {
  background-color: #343839;
}
tr:nth-child(odd) {
  background-color: #232627;
}

.upload-instructions h3 {
  color: #333;
  margin-bottom: 0.5rem;
}

.upload-instructions p {
  color: #666;
}

.input-methods {
  margin-bottom: 1rem;
  text-align: center;
}

.method-button {
  padding: 0.5rem 1rem;
  margin-right: 0.5rem;
  border: none;
  border-radius: 4px;
  background-color: #e7e7e7;
  cursor: pointer;
  outline: none;
  transition: background-color 0.3s;
}

.method-button.active {
  background-color: #4caf50;
  color: white;
}

/*.input-area {
   text-align: center; 
}*/

.file-upload-label {
  display: inline-block;
  padding: 0.5rem 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: transparent;
  cursor: pointer;
}

.file-upload-label:hover {
  background-color: transparent;
}

.text-input-area textarea {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 0.5rem;
}

.text-input-area textarea:focus {
  outline: none;
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.question-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
  padding: 1rem;
}

.question-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.tag {
  background-color: #eef;
  padding: 0.25rem 0.75rem;
  border-radius: 12px;
  font-size: 0.75rem;
  color: #555;
}

.admin-review-button {
  padding: 0.5rem 1rem;
  background-color: #faa;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  color: #fff;
  font-weight: bold;
}

.admin-review-button:hover {
  background-color: #f88;
}

/* Ensure that the footer does not overlap content on smaller screens */
@media (max-width: 768px) {
  .question-footer {
    flex-direction: column;
    align-items: flex-start;
  }

  .admin-review-button {
    margin-top: 1rem;
    width: 100%;
  }
}

/* FilterButtons.css */
.filter-buttons-container {
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 1000;
  display: flex;
  gap: 10px;
  /* Adjust the space between buttons */
}

.filter-button {
  padding: 10px 20px;
  border: none;
  background-color: #f0f0f0;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  font-weight: bold;
  background-color: #f0f0f0;
  color: #333;
}

.filter-button:hover,
.filter-button.active {
  background-color: #007bff;
  color: white;
  transform: translateY(-2px);
  background-color: #007bff;
  color: white;
}

.filter-button:active {
  transform: translateY(0);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.navbar {
  background-color: #f9f9f9;
  /* Lighter background */
  color: #333;
  height: 60px;
  /* Adjusted for potential extra height needed */
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  /* Center the content horizontally */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  /* Softer shadow */
}

.navbar-container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  /* Space out the children elements */
  align-items: center;
}

.submit-button-area {
  width: 100%;
  display: flex;
  justify-content: center;
}

.submit-button {
  padding: 10px 30px;
  border: none;
  background-color: #2829a7;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin: 2% auto;
}

.submit-button:hover {
  background-color: rgb(33, 95, 136);
}

/* This is for when you have an input[type='file'] */
#file-upload {
  display: none;
}

.lever {
  width: 50px;
  height: 200px;
  background-color: #ddd;
  position: relative;
  border: 1px solid #ccc;
  margin: 20px;
  user-select: none;
}

.handle {
  width: 50px;
  height: 50px;
  background-color: #bbb;
  position: absolute;
  bottom: 0;
  cursor: ns-resize;
  /* This gives a north-south resize cursor indication */
}

.dropdown-container {
  margin: 20px;
  display: flex;
  flex-direction: column;
}

.dropdown {
  margin-top: 5px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: white;
  font-size: 16px;
  cursor: pointer;
}

.dropdown:focus {
  outline: none;
  border-color: #4a90e2;
}

@import url(https://fonts.googleapis.com/css?family=Lato:400,100,100italic,300,300italic,400italic,700italic,700,900italic,900);
@import url(https://fonts.googleapis.com/css?family=Raleway:400,100,200,300,500,600,700,800,900);
@import url(https://fonts.googleapis.com/css?family=Raleway:400,100,200,300,500,600,700,800,900);

body {
  background-color: #eee;
}

#generic_price_table {
  background-color: #f0eded;
}

/*PRICE COLOR CODE START*/

.pricing-subscribe {
  margin: auto;
}
#generic_price_table .generic_content {
  background-color: #fff;
}

#generic_price_table .generic_content .generic_head_price {
  background-color: #f6f6f6;
}

#generic_price_table .generic_content .generic_head_price .generic_head_content .head_bg {
  border-color: #e4e4e4 rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #e4e4e4;
}

#generic_price_table .generic_content .generic_head_price .generic_head_content .head span {
  color: #525252;
}

#generic_price_table .generic_content .generic_head_price .generic_price_tag .price .sign {
  color: #414141;
}

#generic_price_table .generic_content .generic_head_price .generic_price_tag .price .currency {
  color: #414141;
}

#generic_price_table .generic_content .generic_head_price .generic_price_tag .price .cent {
  color: #414141;
}

#generic_price_table .generic_content .generic_head_price .generic_price_tag .month {
  color: #414141;
}

#generic_price_table .generic_content .generic_feature_list ul li {
  color: #a7a7a7;
}

#generic_price_table .generic_content .generic_feature_list ul li span {
  color: #414141;
}

#generic_price_table .generic_content .generic_feature_list ul li:hover {
  background-color: #e4e4e4;
  border-left: 5px solid #2ecc71;
}

#generic_price_table .generic_content .generic_price_btn a {
  border: 1px solid #2ecc71;
  color: #2ecc71;
}

#generic_price_table .generic_content.active .generic_head_price .generic_head_content .head_bg,
#generic_price_table .generic_content:hover .generic_head_price .generic_head_content .head_bg {
  border-color: #2ecc71 rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #2ecc71;
  color: #fff;
}

#generic_price_table .generic_content:hover .generic_head_price .generic_head_content .head span,
#generic_price_table .generic_content.active .generic_head_price .generic_head_content .head span {
  color: #fff;
}

#generic_price_table .generic_content:hover .generic_price_btn a,
#generic_price_table .generic_content.active .generic_price_btn a {
  background-color: #2ecc71;
  color: #fff;
}

#generic_price_table {
  margin: 50px 0 50px 0;
  font-family: "Raleway", sans-serif;
}

.row .table {
  padding: 28px 0;
}

/*PRICE BODY CODE START*/

#generic_price_table .generic_content {
  overflow: hidden;
  position: relative;
  text-align: center;
}

#generic_price_table .generic_content .generic_head_price {
  margin: 0 0 20px 0;
}

#generic_price_table .generic_content .generic_head_price .generic_head_content {
  margin: 0 0 50px 0;
}

#generic_price_table .generic_content .generic_head_price .generic_head_content .head_bg {
  border-style: solid;
  border-width: 90px 1411px 23px 399px;
  position: absolute;
}

#generic_price_table .generic_content .generic_head_price .generic_head_content .head {
  padding-top: 40px;
  position: relative;
  z-index: 1;
}

#generic_price_table .generic_content .generic_head_price .generic_head_content .head span {
  font-family: "Raleway", sans-serif;
  font-size: 28px;
  font-weight: 400;
  letter-spacing: 2px;
  margin: 0;
  padding: 0;
  text-transform: uppercase;
}

#generic_price_table .generic_content .generic_head_price .generic_price_tag {
  padding: 0 0 20px;
}

#generic_price_table .generic_content .generic_head_price .generic_price_tag .price {
  display: block;
}

#generic_price_table .generic_content .generic_head_price .generic_price_tag .price .sign {
  display: inline-block;
  font-family: "Lato", sans-serif;
  font-size: 28px;
  font-weight: 400;
  vertical-align: middle;
}

#generic_price_table .generic_content .generic_head_price .generic_price_tag .price .currency {
  font-family: "Lato", sans-serif;
  font-size: 60px;
  font-weight: 300;
  letter-spacing: -2px;
  line-height: 60px;
  padding: 0;
  vertical-align: middle;
}

#generic_price_table .generic_content .generic_head_price .generic_price_tag .price .cent {
  display: inline-block;
  font-family: "Lato", sans-serif;
  font-size: 24px;
  font-weight: 400;
  vertical-align: bottom;
}

#generic_price_table .generic_content .generic_head_price .generic_price_tag .month {
  font-family: "Lato", sans-serif;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 3px;
  vertical-align: bottom;
}

#generic_price_table .generic_content .generic_feature_list ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

#generic_price_table .generic_content .generic_feature_list ul li {
  font-family: "Lato", sans-serif;
  font-size: 18px;
  padding: 15px 0;
  transition: all 0.3s ease-in-out 0s;
}

#generic_price_table .generic_content .generic_feature_list ul li:hover {
  transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  -webkit-transition: all 0.3s ease-in-out 0s;
}

#generic_price_table .generic_content .generic_feature_list ul li .fa {
  padding: 0 10px;
}

#generic_price_table .generic_content .generic_price_btn {
  margin: 20px 0 32px;
}

#generic_price_table .generic_content .generic_price_btn a {
  border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
  -webkit-border-radius: 50px;
  display: inline-block;
  font-family: "Lato", sans-serif;
  font-size: 18px;
  outline: medium none;
  padding: 12px 30px;
  text-decoration: none;
  text-transform: uppercase;
}

#generic_price_table .generic_content,
#generic_price_table .generic_content:hover,
#generic_price_table .generic_content .generic_head_price .generic_head_content .head_bg,
#generic_price_table .generic_content:hover .generic_head_price .generic_head_content .head_bg,
#generic_price_table .generic_content .generic_head_price .generic_head_content .head h2,
#generic_price_table .generic_content:hover .generic_head_price .generic_head_content .head h2,
#generic_price_table .generic_content .price,
#generic_price_table .generic_content:hover .price,
#generic_price_table .generic_content .generic_price_btn a,
#generic_price_table .generic_content:hover .generic_price_btn a {
  transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  -webkit-transition: all 0.3s ease-in-out 0s;
}

@media (max-width: 320px) {
}

@media (max-width: 767px) {
  #generic_price_table .generic_content {
    margin-bottom: 75px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  #generic_price_table .col-md-3 {
    float: left;
    width: 50%;
  }

  #generic_price_table .col-md-4 {
    float: left;
    width: 50%;
  }

  #generic_price_table .generic_content {
    margin-bottom: 75px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
}

@media (min-width: 1200px) {
}

#generic_price_table_home {
  font-family: "Raleway", sans-serif;
}

.text-center h1,
.text-center h1 a {
  color: #7885cb;
  font-size: 30px;
  font-weight: 300;
  text-decoration: none;
}

.demo-pic {
  margin: 0 auto;
}

.demo-pic:hover {
  opacity: 0.7;
}

#generic_price_table_home ul {
  margin: 0 auto;
  padding: 0;
  list-style: none;
  display: table;
}

#generic_price_table_home li {
  float: left;
}

#generic_price_table_home li + li {
  margin-left: 10px;
  padding-bottom: 10px;
}

#generic_price_table_home li a {
  display: block;
  width: 50px;
  height: 50px;
  font-size: 0px;
}

#generic_price_table_home .blue {
  background: #3498db;
  transition: all 0.3s ease-in-out 0s;
}

#generic_price_table_home .emerald {
  background: #2ecc71;
  transition: all 0.3s ease-in-out 0s;
}

#generic_price_table_home .grey {
  background: #7f8c8d;
  transition: all 0.3s ease-in-out 0s;
}

#generic_price_table_home .midnight {
  background: #34495e;
  transition: all 0.3s ease-in-out 0s;
}

#generic_price_table_home .orange {
  background: #e67e22;
  transition: all 0.3s ease-in-out 0s;
}

#generic_price_table_home .purple {
  background: #9b59b6;
  transition: all 0.3s ease-in-out 0s;
}

#generic_price_table_home .red {
  background: #e74c3c;
  transition: all 0.3s ease-in-out 0s;
}

#generic_price_table_home .turquoise {
  background: #1abc9c;
  transition: all 0.3s ease-in-out 0s;
}

#generic_price_table_home .blue:hover,
#generic_price_table_home .emerald:hover,
#generic_price_table_home .grey:hover,
#generic_price_table_home .midnight:hover,
#generic_price_table_home .orange:hover,
#generic_price_table_home .purple:hover,
#generic_price_table_home .red:hover,
#generic_price_table_home .turquoise:hover {
  border-bottom-left-radius: 50px;
  border-bottom-right-radius: 50px;
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
  transition: all 0.3s ease-in-out 0s;
}

#generic_price_table_home .divider {
  border-bottom: 1px solid #ddd;
  margin-bottom: 20px;
  padding: 20px;
}

#generic_price_table_home .divider span {
  width: 100%;
  display: table;
  height: 2px;
  background: #ddd;
  margin: 50px auto;
  line-height: 2px;
}

#generic_price_table_home .itemname {
  text-align: center;
  font-size: 50px;
  padding: 50px 0 20px;
  border-bottom: 1px solid #ddd;
  margin-bottom: 40px;
  text-decoration: none;
  font-weight: 300;
}

#generic_price_table_home .itemnametext {
  text-align: center;
  font-size: 20px;
  padding-top: 5px;
  text-transform: uppercase;
  display: inline-block;
}

#generic_price_table_home .footer {
  padding: 40px 0;
}

.price-heading {
  text-align: center;
}

.price-heading h1 {
  color: #666;
  margin: 0;
  padding: 0 0 50px 0;
}

.demo-button {
  background-color: #333333;
  color: #ffffff;
  display: table;
  font-size: 20px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 50px;
  outline-color: -moz-use-text-color;
  outline-style: none;
  outline-width: medium;
  padding: 10px;
  text-align: center;
  text-transform: uppercase;
}

.bottom_btn {
  background-color: #333333;
  color: #ffffff;
  display: table;
  font-size: 28px;
  margin: 60px auto 20px;
  padding: 10px 25px;
  text-align: center;
  text-transform: uppercase;
}

.demo-button:hover {
  background-color: #666;
  color: #fff;
  text-decoration: none;
}

.bottom_btn:hover {
  background-color: #666;
  color: #fff;
  text-decoration: none;
}
.navigation {
  gap: 1rem;
  margin-left: 1rem;
}

.navigation a {
  text-decoration: none;
  color: rgb(156 163 175);

  font-size: 0.875rem;
}

.navigation a:hover {
  color: #e5e7eb;
}

.price {
  font-size: 2rem;
  color: #007bff;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
}

.routescomponents {
  margin-top: 3.125rem;
}

.result-component .result-table,
.result-component .result-table th,
.result-component .result-table td {
  border: 1px solid rgba(254, 254, 254, 0.1);
  text-align: left;
}

.result-component .result-table th {
  background-color: #232627;
}

.result-component .error-message {
  color: #ff0000 !important;
  font-weight: bold;
}

.result-component .string-response {
  font-style: italic;
}

.result-component .correct-answer {
  color: #008000;
  font-weight: bold;
}

.result-component .incorrect-answer {
  color: #ff0000;
  font-weight: bold;
}

.ace_editor,
.ace_editor * {
  font-family: "Fira Code", "Consolas", "Courier New", monospace, "Monaco", "Menlo", "Ubuntu Mono", "Droid Sans Mono", "Consolas", monospace !important;
  font-weight: 400 !important;
  letter-spacing: 0 !important;
  font-size: 14px !important;
}

.topic-item,
.company-item {
  padding: 5px 10px;
  border: 1px solid #ccc;
  margin: 5px;
  display: inline-block;
  cursor: pointer;
}

.selected {
  background-color: #007bff;
  color: white;
}

.remove-icon {
  margin-left: 8px;
  cursor: pointer;
}
.table-heading-button {
  color: rgba(108, 114, 117, 1);
  width: Fill (144.67px);
  height: Hug (32px);
  padding: 4px, 8px, 4px, 8px;
  border-radius: 8px;
  gap: 8px;
}
.table-heading-button:hover,
.active-table {
  color: rgba(232, 236, 239, 1);
  background: rgba(52, 56, 57, 1);
  transition: background-color 0.3 ease;
}
.dataset-heading-list-container {
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: row;
  background: rgba(35, 38, 39, 1);
  justify-content: space-between;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
/*
.MuiTooltip-tooltip {
  background: rgba(35, 38, 39, 1);
  color: rgba(219, 219, 219, 1);
  overflow:auto;
  border-radius:8px;
  border: 1px solid rgba(57, 59, 60, 1);

} */
.utility-buttons-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-self: flex-end;
  border-radius: 8px;
  border: 0px, 0px, 1px, 0px;
  background: rgba(0, 0, 0, 1);
  padding: 8px;
  position: sticky;
  bottom: 0;
  width: 100%;
}
.hints-button {
  margin: 0px 8px 0px 0px;
  background-color: transparent;
  color: rgba(108, 114, 117, 1);
  width: auto;
}
.current-level-container {
  background: rgba(44, 32, 18, 1);
  color: rgba(255, 159, 42, 1);
  width: 18%;
  padding: 5px;
  border-radius: 8px;
  text-align: center;
}
.metrics-container {
  /* background: rgba(44, 32, 18, 1); */
  background: linear-gradient(90deg, rgba(255, 200, 100, .2), rgba(255, 100, 0, .1));
  color: rgba(255, 159, 42, 1);
  /* width:auto; */
  border-radius: 8px;
  font-size: 14px;
}

.questions-count-heading {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #343839;
  font-size: 15px;
  font-weight: bold;
  background-color: white;
  margin: 0px 0.5rem;
}
.hints-button:hover {
  margin: 0px 8px 0px 0px;
  background: rgba(44, 32, 18, 1);
  color: rgba(255, 159, 42, 1);
}
.display-container {
  flex-grow: 1;
}
.input-area {
  background-color: #232627;
}
.drop-down-container {
  background: #343839;
  padding: 6px 8px;
  border-radius: 8px;
  font-size: 0.875rem;
}
.drop-down-container:focus {
  outline: none;
}
.result-heading-container {
  background-color: #232627;
  display: flex;
  justify-content: flex-start;
}
.run-btn {
  background-color: #343839;
  border-radius: 8px;
  color: white;
  margin-right: 8px;
  padding: 0.17rem 0.3rem;
  font-size: 0.875rem;
  font-weight: 600;
}
.header-btns-container {
  display: flex;
}
button:hover {
  opacity: 85%;
  border-color: none;
}
.navigation {
  color: white;
}
.submit-btn {
  color: #fefefe;
  border-radius: 8px;
  padding: 0.17rem 0.3rem;
  font-size: 0.875rem;
  font-weight: 600;
}
.run-submit-btn-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  background-color: #232627;
}
.manual-review-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-self: flex-end;
  background: #23262780;
  border-radius: 8px;
}
.manual-review-button {
  background-color: #fefefe;
  border-radius: 8px;
  color: black;
  padding: 0.17rem 0.3rem;
  font-size: 0.875rem;
  font-weight: 600;
}
/* Resizer.vertical {
  width:8px;
  background-color: #141718;
  cursor: col-resize;
  border-radius:8px;
  margin:10px 0px;
}
Resizer.horizontal {
  height:8px;
  width:95%;
  cursor: row-resize;
  background-color: #343839;
  border-radius:8px;
  margin:5px 10px;
}
Resizer.vertical:hover {
  width:9px;
  background-color:#343839;
  
} */

.Resizer {
  background: #000;
  opacity: 0.2;
  z-index: 1;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -moz-background-clip: padding;
  -webkit-background-clip: padding;
  background-clip: padding-box;
}

.Resizer:hover {
  -webkit-transition: all 2s ease;
  transition: all 2s ease;
}

.Resizer.horizontal {
  height: 11px;
  margin: -5px 0;
  border-top: 5px solid rgba(255, 255, 255, 0);
  border-bottom: 5px solid rgba(255, 255, 255, 0);
  cursor: row-resize;
  width: 100%;
}

.Resizer.horizontal:hover {
  border-top: 5px solid rgba(0, 0, 0, 0.5);
  border-bottom: 5px solid rgba(0, 0, 0, 0.5);
}

.Resizer.vertical {
  width: 11px;
  margin: 0 -5px;
  border-left: 5px solid rgba(255, 255, 255, 0);
  border-right: 5px solid rgba(255, 255, 255, 0);
  cursor: col-resize;
}

.Resizer.vertical:hover {
  border-left: 5px solid rgba(0, 0, 0, 0.5);
  border-right: 5px solid rgba(0, 0, 0, 0.5);
}
.Resizer.disabled {
  cursor: not-allowed;
}
.Resizer.disabled:hover {
  border-color: transparent;
}
.table-headings {
  display: flex;
  flex-direction: row;
  overflow: auto;
}
.table-headings li {
  font-size: 1rem;
}
.excel-solution-area {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  padding: 10px;
  height: 300px;
}
.excel-download-area,
.file-upload-area {
  width: 50%;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}
.excel-download-area h5,
.file-upload-area h5 {
  font-weight: 700;
  margin-bottom: 1rem;
}
.excel-download-area button,
.file-upload-area button {
  margin-left: auto;
  margin-right: auto;
}
.level-button,
.level-button-selected {
  height: 1.68rem;
  width: 2rem;
  cursor: pointer;
  margin: 0px 0.5rem;
  padding: 0.5rem;
  border: 1px solid #fefefe;
  display: flex; /* Add this line */
  align-items: center; /* Add this line */
  justify-content: center;
  color: #fefefe;
  border-radius: 0.375rem;
  font-size: 0.875rem;
}
.level-button-selected {
  border: 2.5px solid #fefefe;
  font-weight: 900;
}

.flex-row-container,
.flex-row-bw-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.flex-row-bw-container {
  justify-content: space-between;
}
.problem-heading-container {
}
.option-hide {
  display: none;
}
.problem-statement-description {
  user-select: none;
  max-height: 150px;
  padding-right: 0.3rem;
  overflow-y: auto;
}
.answer-hints-container {
  font-size: 1rem;
}
.post-login-pages-main-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #232627;
  height: calc(100vh - 50px);
  color: #fefefe;
}
.toggle-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: auto;
  padding: 0.15rem 0.5rem;
  border-radius: 25px;
  background-color: #615fc48a;
  border: none;
  cursor: pointer;
  position: relative;
  color: #fefefe;
  transition: background-color 0.3s ease;
}

.toggle-button.active {
  background-color: #47ab508a;
}

.toggle-indicator {
  width: 18px;
  height: 18px;
  background-color: #fefefe;
  border-radius: 50%;
  position: absolute;
  right: 5px;
  transition: all 0.3s ease;
}

.toggle-button.active .toggle-indicator {
  left: auto;
  right: 5px;
}
.toggle-button .toggle-indicator {
  right: auto;
  left: 5px;
}
.toggle-button span {
  padding-right: 0px;
  padding-left: 1.25rem;
}
.toggle-button.active span {
  padding-left: 0px;
  padding-right: 1.25rem;
}
.timer-container {
  display: flex;
  align-items: center;
  position: relative;
  padding: 0.25rem 1rem;

  background-color: #2c2012;
  color: #ff9f2a;
  border-radius: 8px;
}

.loading-custom-color .MuiCircularProgress-root {
  color: #9ca3af; /* Gray loader color */
}

.gradient {
  height: 3px;
  border: none;
  border-radius: 6px;
  background: linear-gradient(90deg, #191919 0%, #a9a9a9 50%, #191919 100%);
}

.userDrop {
  padding: 0;
  background-color: #f43f5e;
  width: 1.5rem;
  height: 1.5rem;
  border-radius:100% 100% 100% 0%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: bold;
  transform: rotate(-45deg); /* Fix rotation direction of background */
}

.userDrop div {
  transform: rotate(45deg); /* Reverts rotation for the text */
}


