input[type="date"]::-webkit-calendar-picker-indicator {
    cursor: pointer;
    border-radius: 4px;
    margin-right: 2px;
    opacity: 0.6;
    filter: invert(0.8);
  }
  
  input[type="date"]::-webkit-calendar-picker-indicator:hover {
    opacity: 1
  }
 /* profile.css */

.profile-page-container {
    background-color: #232627;
    color: rgb(156 163 175);
    padding: 20px;
}

.profile-cards-container {
    width: 25%;
    align-self: flex-start;
    position: sticky;
    top:3.5rem;   
}

.profile-card {
    background-color: transparent;
    color: #e5e7eb;
    margin-bottom: 10px;
    border-radius: 12px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 100%;
    
}

.profile-card.active {
    background-color: #1f2021;
    
}

.profile-details-container {
    width: 70%;
    border-radius: 10px;
}

.profile-input, .profile-select, .profile-textarea {
    color: #e5e7eb;
    font-weight:400;
    font-size: .875rem;
    line-height: 1.5rem;
    padding:0.4rem 0.6rem;
    border: 1px solid #e5e7eb1a;
    background-color: transparent;
}


.profile-input:disabled, .profile-select:disabled, .profile-textarea:disabled {
    background-color: transparent;
    border:0;
    padding-left:0px;
}

.edit-button-container {
    margin-top: 20px;
}

.profile-edit-btn, .profile-update-btn {
    padding: 5px 15px;
    margin-right: 10px;
    border: none;
    border-radius: 12px;
    cursor: pointer;
    background-color: #47ab50;
    color: white;
}

.profile-edit-btn:hover, .profile-update-btn:hover {
    background-color: #3b9e43;
}
 
.profile-legend {
    font-size: .875rem;
    line-height: 1.5rem;
    
}
