.getInCard1 {
  backdrop-filter: blur(34px);
  background-image: linear-gradient(186deg, rgba(0, 0, 0, 0) 90%, rgba(213, 97, 254, .7)), linear-gradient(6deg, rgba(0, 0, 0, 0) 90%, #9e9cff);
  border-radius: 24px;

}

.getInCard2 {
  -webkit-backdrop-filter: blur(34px);
  backdrop-filter: blur(34px);
  background-color: rgba(0, 0, 0, 0);
  background-image: linear-gradient(26deg, rgba(0, 0, 0, 0) 45%, rgba(255, 255, 255, .12));
  border-radius: 24px;
}

/* inspired from this svgbackgrounds.com/ */
.cta-btn {
  /* width: fit-content; */
  border: none;
  cursor: pointer;
  text-shadow: 2px 2px 3px rgb(136 0 136 / 50%);
  background: linear-gradient(15deg,
      #562E7E,
      #9c3ef9,
      #7129b9) no-repeat;
  background-size: 300%;
  background-position: left center;
  transition: background 0.3s ease;
  color: #fff;
}

.cta-btn:hover {
  background-size: 320%;
  background-position: right center;
}

/* .cta-btn-outlined {
  @apply bg-gradient-to-r from-[#ff9cc0] to-transparent text-white py-1 px-3 shadow-md hover:from-pink-500 transition-all
} */

.cta-btn-outlined {
  @apply bg-slate-700 font-normal text-white py-1 px-3 shadow-md hover:from-pink-500 transition-all
}