.badge-container {
    display: flex;
    gap: 0.625rem;
    flex-direction: row;
    margin: 0px 0.5rem;
    align-items: center;
  }
  
  .badge {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-weight: bold;
    font-size: 12px;
  }
  
  .bronze { background-color: #cd7f32; }
  .silver { background-color: #c0c0c0; }
  .gold { background-color: #d4af37; }
  .platinum { background-color: #e5e4e2; }
  