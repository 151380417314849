.insightCard {
    display: flex;
    flex-direction: column;
    isolation: isolate;
    position: relative;
    overflow: hidden;
    font-size: 14px;
  
    background: #29292c;
    border-radius: 1rem;
    --color: #32a6ff
  }
  
  
  .insightCard:after {
    position: absolute;
    content: "";
    width: 0.25rem;
    inset: 0.65rem auto 0.65rem 0.5rem;
    border-radius: 0.125rem;
    background: var(--gradient);
    transition: transform 300ms ease;
    z-index: 4;
  }
  
  .insightCard:hover:after {
    transform: translateX(0.15rem)
  }
  
  .insightTitle {
    /* color: var(--color); */
    background-image: linear-gradient(96deg, #dddcff, #c1bfff);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    padding: 0.65rem 0.25rem 0.4rem 1.25rem;
    font-weight: 500;
    font-size: 16px;
    transition: transform 300ms ease;
    z-index: 5;
  }
  
  .insightCard:hover .insightTitle {
    transform: translateX(0.15rem)
  }
  
  .insightCardBody {
    padding: 0 1.25rem;
    transition: transform 300ms ease;
    z-index: 5;
  }
  
  .insightCard:hover .insightCardBody {
    transform: translateX(0.15rem)
  }
  
  .notiglow,
  .notiborderglow {
    position: absolute;
    width: 20rem;
    height: 20rem;
    transform: translate(-50%, -50%);
    background: radial-gradient(circle closest-side at center, white, transparent);
    opacity: 0;
    transition: opacity 300ms ease;
  }
  
  .notiglow {
    z-index: 3;
  }
  
  .notiborderglow {
    z-index: 1;
  }
  
  .insightCard:hover .notiglow {
    opacity: 0.1
  }
  
  .insightCard:hover .notiborderglow {
    opacity: 0.1
  }