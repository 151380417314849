.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal {
    background: #232627;
    color:white;
    border-radius: 8px;
    opacity:95%;
    backdrop-filter: blur(30px);
    -webkit-backdrop-filter: blur(30px);
    padding: 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
    margin:8px;
    max-width:66%;
    max-height: 85%;
  }
  
  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    
  }
  
  .modal-close-button {
    border: none;
    background: none;
    font-size: 1.5rem;
    cursor: pointer;
  }
  