.coinstatic {
    /* margin: auto; */
    height: 16px;
    width: 16px;
}

.coinstatic .front,
.coinstatic .back {
    position: absolute;
    height: 16px;
    width: 16px;
    background: #ffbd0b;
    border-radius: 50%;
    border-top: 0.75px solid #ffd84c;
    border-left: 0.75px solid #ffd84c;
    border-right: 0.75px solid #d57e08;
    border-bottom: 0.75px solid #d57e08;
    transform: rotate(44deg);
}

.coinstatic .front:before,
.coinstatic .back:before {
    content: "";
    margin: 3.8px 3.8px;
    position: absolute;
    width: 7px;
    height: 7px;
    background: #f0a608;
    border-radius: 50%;
    border-bottom: 0.5px solid #ffd84c;
    border-right: 0.5px solid #ffd84c;
    border-left: 0.5px solid #d57e08;
    border-top: 0.5px solid #d57e08;
    z-index: 2;
}

.coinstatic .front .currency,
.coinstatic .back .currency {
    overflow: hidden;
    position: absolute;
    color: #ffbd0b;
    font-size: 4px;
    transform: rotate(-44deg);
    line-height: 3.7;
    width: 100%;
    height: 100%;
    text-align: center;
    text-shadow: 0 0.3px 0 #cb7407;
    z-index: 3;
    border-radius: 50%;
}

.coinstatic .front .currency:after,
.coinstatic .back .currency:after {
    content: "";
    position: absolute;
    height: 20px;
    width: 4px;
    margin: 2px -6.5px;
    box-shadow: 5px -2.3px 0 -1px rgba(255, 255, 255, 0.22), 8.5px -1px 0 -1.6px rgba(255, 255, 255, 0.19);
    transform: rotate(-50deg);
    animation: shine 1.5s infinite ease;
}

.coinstatic .front .shapes,
.coinstatic .back .shapes {
    transform: rotate(-44deg);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.coinstatic .front .shapes div,
.coinstatic .back .shapes div {
    width: 2px;
    height: 0.4px;
    background: #d57e08;
    border-top: 0.2px solid #c47207;
    margin: 7.5px 0.7px;
}

.coinstatic .front .shapes div:before,
.coinstatic .back .shapes div:before {
    content: "";
    position: absolute;
    width: 2px;
    height: 0.4px;
    background: #d57e08;
    border-top: 0.2px solid #c47207;
    margin: -1px 0;
}

.coinstatic .front .shapes div:after,
.coinstatic .back .shapes div:after {
    content: "";
    position: absolute;
    width: 2px;
    height: 0.4px;
    background: #d57e08;
    border-top: 0.2px solid #c47207;
    margin: 0.8px 0;
}

.coinstatic .front .shape_l,
.coinstatic .back .shape_l {
    float: left;
}

.coinstatic .front .shape_r,
.coinstatic .back .shape_r {
    float: right;
}

.coinstatic .front .top,
.coinstatic .back .top {
    font-size: 3px;
    color: #d67f08;
    text-align: center;
    width: 100%;
    position: absolute;
    left: 0;
}

.coinstatic .front .bottom,
.coinstatic .back .bottom {
    font-size: 3px;
    color: #d67f08;
    text-align: center;
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
}

.coinstatic .shadow {
    width: 100%;
    height: 2px;
    background: rgba(0, 0, 0, 0.4);
    left: 0;
    bottom: -5px;
    border-radius: 50%;
    z-index: -1;
    margin: 18.5px 0.7px 0 0.7px;
    animation: swift 1.5s infinite ease;
}
