.content {
  padding: 20px;
  height: 100%;
}


.content .description {
  /* color: rgb(230, 230, 230); */
  margin-top: 10px;
  font-size: 16px;
  text-align: left;
  line-height: 22px;
}

.content .title {
  font-weight: 600;
  background-image: linear-gradient(96deg, #dddcff, #c1bfff);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;


  margin-top: 10px;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
}

.myCard {
  background-color: #1A2029;
  border-radius: 18px;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  background-clip: padding-box;
}

.shiny-border {
  position: relative;
  background: linear-gradient(to top right, rgb(106, 106, 106) 1%, #1A2029);
  padding: 1px;
  border-radius: 18px;
  transition: 0.3s ease-in-out, transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.content-wrapper-popup {
  background-color: transparent; /* Inner content transparent */
  border-radius: inherit; /* Ensures the inner div matches the rounded corners of the border */
}

.shiny-border-new {
  position: relative;
  background: linear-gradient(to top right, rgb(106, 106, 106), #1A2029, #1A2029);
  padding: 1px;
  border-radius: 18px;
  transition: 0.3s ease-in-out, transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;

}


.myCard1 {
  background-image: radial-gradient(circle at 100% 0, rgba(158, 156, 255, .4), rgba(0, 0, 0, 0) 8%), radial-gradient(circle at 100% 0, rgba(158, 156, 255, .3), rgba(23, 25, 30, 0) 12%);
}


.myCard2 {
  background-image: radial-gradient(circle at 100% 0, rgba(255, 156, 192, .3), rgba(0, 0, 0, 0) 8%), radial-gradient(circle at 100% 0, rgba(255, 156, 192, .35), rgba(23, 25, 30, 0) 12%);
}

.myCard3 {
  background-image: radial-gradient(circle at 100% 0, rgba(255, 192, 156, .25), rgba(0, 0, 0, 0) 8%), radial-gradient(circle at 100% 0, rgba(255, 192, 156, .32), rgba(23, 25, 30, 0) 12%);
}

/* .shiny-border:hover,
.shiny-border-new:hover {
  transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
} */