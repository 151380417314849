.home-page-background {
    background-color: #060515;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
}

.css-typing1 h4 {
    border: 0px;
    border-right: .08em solid white;
    white-space: nowrap;
    overflow: hidden;
    max-width: 100%;
    font-size: 56px;
    font-weight: 400;
    line-height: 61.6px;
    letter-spacing: -0.02em;
    text-align: left;

}

.css-typing1 h4:nth-child(1) {
    width: 16ch;
    /* manually set width */
    -webkit-animation: type 2s steps(16, end);
    animation: type 2s steps(16, end);
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
}

.css-typing1 h4:nth-child(2) {
    width: 17ch;
    /* manually set width */
    opacity: 0;
    -webkit-animation: type2 2s steps(21, end);
    animation: type2 2s steps(21, end);
    -webkit-animation-delay: 2s;
    animation-delay: 2s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
}

.css-typing1 h4:nth-child(3) {
    width: 18ch;
    /* manually set width */
    opacity: 0;
    -webkit-animation: type2 5s steps(20, end), blink .5s step-end infinite alternate;
    animation: type2 2s steps(20, end), blink .5s step-end infinite alternate;
    -webkit-animation-delay: 4s;
    animation-delay: 4s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
}

@keyframes type {
    0% {
        width: 0;
    }

    99.9% {
        border-right: .08em solid white;
    }

    100% {
        border: none;
    }
}

@-webkit-keyframes type {
    0% {
        width: 0;
    }

    99.9% {
        border-right: .08em solid white;
    }

    100% {
        border: none;
    }
}

@keyframes type2 {
    0% {
        width: 0;
    }

    1% {
        opacity: 1;
    }

    99.9% {
        border-right: .08em solid white;
    }

    100% {
        opacity: 1;
        border: none;
    }
}

@-webkit-keyframes type2 {
    0% {
        width: 0;
    }

    1% {
        opacity: 1;
    }

    99.9% {
        border-right: .08em solid white;
    }

    100% {
        opacity: 1;
        border: none;
    }
}


@keyframes blink {
    50% {
        border-color: transparent;
    }
}

@-webkit-keyframes blink {
    50% {
        border-color: transparent;
    }
}

@keyframes blink {
    50% {
        border-color: transparent;
    }
}

@-webkit-keyframes blink {
    50% {
        border-color: transparent;
    }
}

@media (max-width:768px) {
    .css-typing1 {
        font-size: 17px;
    }
}

.hero-content-container {
    flex-grow: 1;
}

.side-design {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 200px;
    /* Adjust based on your design size */
    background-size: contain;
    background-repeat: no-repeat;
}

.left-design {
    top: 150px;
    width: 215.3px;
    height: 325.06px;
    left: 11.8px;
    gap: 0px;

    left: 0;
    background-image: url('../image_files/heroLeft.png');
    background-position: left;
}

.right-design {
    top: 95px;
    height: 360px;
    right: 0px;
    margin-left: 20px;
    background-image: url('../image_files/heroRight.png');
    background-position: right;
}

.hero-image {
    width: 446px;
    height: 419px;
    gap: 0px;



}

.home-page-main-illustration {
    box-shadow: linear-gradient(180deg, rgba(111, 48, 203, 0.2) 0%, rgba(73, 113, 255, 0.2) 100%);
}

.home-page-nav-bar {
    border: 0.05px solid;
    border-image-source: linear-gradient(270deg, #151030 0%, #241A4F 100%);
}

.crux-text {
    font-size: 48px;
    font-weight: 600;
    line-height: 52.8px;
    letter-spacing: -0.02em;
    text-align: left;
    margin: 0px 8px;

}

.feature-card-gradient {
    background: linear-gradient(92.09deg, rgba(21, 16, 48, 0.7) 7.97%, rgba(21, 16, 48, 0) 100.29%);
    width: 100%;
    padding: 0px 8px;
    margin-bottom: 40px;
}

.feature-cards-container {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-left: auto;
    margin-right: auto;
    background-image: url('../image_files/featureCardBg.png');
    background-size: cover;
    background-size: 95% 90%;
}

.feature-cards-container::before {}

.feature-card {
    padding: 8px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: Fixed (400px)px;
    height: Fixed (393px)px;
    border-radius: 16px;
    border: 0.05px 0px 0px 0px;
    background: linear-gradient(180deg, rgba(21, 16, 48, 0.9) 49.23%, rgba(21, 16, 48, 0) 100%, rgba(21, 16, 48, 0.774) 100%);
    border: 0.05px solid;
    border-image-source: linear-gradient(270deg, #151030 0%, #241A4F 100%);
    box-shadow: 0px 4px 32px -1px #000000BF;
    margin: 8px;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
}

@media (min-width:1024px) {
    .feature-card {
        padding: 20px;
    }
}

.feature-card .img-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-left: 12px;
}

.feature-card .img-container img {
    background: linear-gradient(180deg, rgb(145, 94, 255) 0%, rgb(0, 126, 236) 100%);
    border-radius: 9999px;
    filter: brightness(1.0);

}

.feature-card span {
    font-size: 28px;
    font-weight: 700;
    line-height: 39.2px;
    text-align: left;
    padding-left: 12px;
}

.feature-card ul {
    margin-left: 12px;
    flex: 1 1 0%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    list-style-position: outside;
    list-style-type: disc;
    padding-left: 32px;
}

.flex-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 12px;
    width: 100%;
}

.mouse-icon-container {
    width: 30px;
    height: 60px;
    border-radius: 24px;
    border: 3px solid;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 24px;
}

.mouse-icon-container img {
    width: 30px;
    height: 40px;
}

.cta-button {
    margin-top: 24px;
}

.get-started-text {
    margin: 30px 0px;
}

.subscribe-container,
.hero-illustration {
    padding: 16px 12px 20px 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}

.hero-illustration {
    padding-top: 20px;
}

.home-page-content-description {
    margin-top: 96px;
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;

}

@media (min-width:768px) {
    .subscribe-container {
        padding: 16px 10px 20px 10px;
        flex-direction: row;
    }

    .home-page-content-description {
        flex-direction: row;
    }
}

.subscribe-heading {
    font-size: 36px;
    font-weight: 700;
    line-height: 54px;
    text-align: left;
}

.subscribe-paragraph {
    margin: 20px 0px;
    font-size: 24px;
    font-weight: 500;
    line-height: 30px;
    text-align: left;

}

.subscribe-card,
.earth-illustration {
    width: 100%;
}

@media (min-width:768px) {

    .subscribe-card,
    .earth-illustration {
        width: 50%;
    }
}

.subscribe-input-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 6px 4px;
    border-radius: 9999px;
    background-color: white;
}

@media (min-width:1024px) {
    .subscribe-input-container {
        width: 75%
    }
}

.subscribe-card {
    background-image: url('../image_files/featureCardBg.png');
    -webkit-backdrop-filter: blur(3px);
    backdrop-filter: blur(3px);
    background-size: cover;

}

.earth-illustration img {
    padding-top: 16px;
    height: 340px;
    object-fit: contain;
    width: 100%;
}

.subscribe-card .feature-card {
    margin: 0px;
}

.footer-container {
    width: 100%;
    background-color: #151030;
    opacity: 75%;
}

@media (min-width:1024px) {
    .footer-container {
        background-image: url('../image_files/Mask Group.svg');
        object-fit: cover;
        background-repeat: no-repeat;
    }
}

.footer-content {
    margin-top: 4px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    margin: auto;
}

@media (min-width:1024px) {
    .footer-content {
        flex-direction: row;

    }
}

@media (min-width: 768px) {
    .footer-content {
        padding: 4px 40px 0px 40px;
    }
}

.footer-content .img-container {
    align-self: flex-start;
    margin-top: 1.25rem;
}


@media (min-width: 640px) {
    .footer-content .img-container {
        width: 100%;
    }
}

@media (min-width: 768px) {
    .footer-content .img-container {
        width: 50%;
    }
}

.footer-content .img-container img {
    object-fit: cover;
    width: 75%;
    height: 85%;
    margin-left: 2rem;
    margin-right: 2rem;
}

.link-section-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 0.5rem;
}

@media (min-width: 1024px) {
    .link-section-container {
        justify-content: space-around;
    }
}

@media (min-width: 768px) {
    .link-section-container {
        flex-direction: row;
    }
}

@media (min-width: 640px) {
    .link-section-container {
        flex-wrap: wrap;
        justify-content: space-evenly;
    }
}

.copy-rights-section {
    text-align: center;
    margin-bottom: 2px;
}

.link-section-heading {
    font-size: 24px;
    font-weight: 700;
    line-height: 62.4px;
    text-align: left;
    color: rgb(96 165 250);
    margin-bottom: 12px;
}

.link-section-links {
    text-decoration: none;
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;
    line-height: 32.2px;
    text-align: left;

}

.subscription-container::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: url('../image_files/featureCardBg.png');
    background-size: cover;
    /* or whatever your preferred sizing is */
    background-position: center;
    /* or whatever your preferred position is */
    filter: brightness(2.9) blur(3px);
    z-index: -1;
    /* Place it underneath the content */
}

.subcription-container,
.feature-card {
    position: relative;
    overflow: hidden;
    /* Ensure the pseudo-element doesn't go outside the bounds */
    z-index: 1;
}

.glowing-card1 {
    position: relative;

    &::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 16px;
        background: radial-gradient(rgba(73, 113, 255, 0.2) 20%, rgba(111, 48, 203, 1) 80%);
        z-index: -1;
        animation: glow 2s ease-in-out infinite;
    }

    @keyframes glow {
        0% {
            filter: brightness(1);
        }

        50% {
            filter: brightness(2);
        }

        100% {
            filter: brightness(1);
        }
    }
}


.glowing-card {
    position: relative;

    &::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 16px;
        background: radial-gradient(circle, rgba(73, 113, 255, 0.35) 0%, rgba(111, 48, 203, 0.02) 80%);
        z-index: -1;
        animation: glow 2s ease-in-out infinite;
    }

    @keyframes glow {
        0% {
            filter: brightness(1);
        }

        50% {
            filter: brightness(2);
        }

        100% {
            filter: brightness(1);
        }
    }
}


.glowing-card2 {
    position: relative;
    overflow: hidden;
    border-radius: 10px;
}

.glowing-card2::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 10px;
    background: linear-gradient(180deg, rgba(255, 173, 255, 0) 0%, #ffadff 50%, rgba(255, 173, 255, 0) 100%);
    filter: blur(10px);
    z-index: -1;
}

.glowing-card2::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    border-radius: 10px;
    background: radial-gradient(circle, transparent 0%, rgba(255, 173, 255, 0.2) 15%, transparent 100%);
    z-index: -1;
}