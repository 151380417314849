/* Sidebar.css */
.sidebar {
  transform: translateX(-100%);
  transition: transform 0.3s ease-in-out;
}

.sidebar.open {
  transform: translateX(0);
}

.close-btn {
  cursor: pointer;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.chrono-card-correct {
  background-color: #47ab508a; /* Green for correct answers */
}

.chrono-card-incorrect {
  background-color: #d9534f8a; /* Slight reddish for incorrect answers */
}

.chrono-card-visited {
  background-color: #615fc48a ;
}
.chrono-container li {
  justify-content: space-between !important;
}
.chrono-card {
  width : auto !important;
  
  background-color: transparent !important;
}
.chrono-card button {
  padding:0px;
}
.chrono-card-content {
  display: flex;
  align-items: center;
  gap: 0.125rem;
  overflow: auto;
  flex-wrap: wrap;
}

.chrono-card-content li {
  display: flex; /* Add this to make li elements flex containers */
  background-color: #fefefe1a;
  padding: 0.1rem 0.1rem;
  border-radius: 8px;
}

.card-left {
  justify-content: flex-end;
}

.card-right {
  justify-content: flex-start;
}
.gyFIuQ {
  width : auto !important;
}
.jjfYoe-left::before {
  background-color: transparent !important;
}
.chrono-icons {
  border-radius: 1.25rem;
}
.status-list {
  list-style-type: disc;
  list-style-position: inside;
  font-size: 30px;
  text-indent: 0;  /* Align marker with text */
  padding-left: 0; /* Reduce the extra space */
}

.style-list li::marker {
  color: red; /* Default color for all markers */
}
.style-list li:nth-child(1)::marker {
  
  color: #22c55e4a;
}
.style-list li:nth-child(2)::marker {
  color: #EAB3084a;
}
.style-list li:nth-child(3)::marker {
  color: #CCC9C94a;
}
/* From https://css.glass */
.solved {
/* From https://css.glass */
background: rgba(78, 144, 103, 0.18);
border-radius: 16px;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(0.3px);
-webkit-backdrop-filter: blur(0.3px);
border: 1px solid rgba(78, 144, 103, 0.3);
}
.attempted {
  background: rgba(234, 179, 8, 0.18);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(0.3px);
  -webkit-backdrop-filter: blur(0.3px);
  border: 1px solid rgba(234, 179, 8, 0.3);  
}
.seen {
background: rgba(191, 188, 188, 0.18);
border-radius: 16px;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(0.3px);
-webkit-backdrop-filter: blur(0.3px);
border: 1px solid rgba(191, 188, 188, 0.3);
}
.current {
  background: rgba(3, 194, 252, 0.18);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(0.3px);
  -webkit-backdrop-filter: blur(0.3px);
  border: 1px solid rgba(3, 194, 252, 0.3);
  }