.mirror-background {
    position: relative;
    width: 100%;
    min-height: 100vh; /* Adjust as per your layout needs */
    overflow-x: hidden;
    display: flex;
    align-items: flex-start; /* Align content to the bottom */
}

.bg-pattern-left, .bg-pattern-right {
    position: fixed;
    bottom: 0;
    height: 100%; /* Cover the entire height of the container */
    background-repeat: no-repeat;
}

.bg-pattern-left {
    left: 0;
    width: 100%; /* Left side takes 50% of the width */
    background-image: url('../../assets/image_files/Mask Group.svg');
    background-position: bottom left; /* Adjust as per your SVG */
}

.bg-pattern-right {
    right: -350px;
    width: 100%; /* Right side takes 50% of the width */
    background-image: url('../../assets/image_files/Mask Group.svg');
    background-position: bottom left; /* Adjust as per your SVG */
    transform: scaleX(-1); /* Mirror horizontally */
}
